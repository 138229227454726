<div class="page-not-found container">
  <h1>404</h1>
  <h2><small>{{"404.page-not-found" | translate}}</small></h2>
  <br/>
  <p>{{"404.help" | translate}}</p>
  <br/>
  <p class="text-center">
    <a routerLink="/documents" class="btn btn-primary">{{"404.link.home-page" | translate}}</a>
  </p>
</div>
