import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { trigger } from '@angular/animations';


@Component({
  selector: 'ds-jose',
  template: '<h1>This is the Jose component</h1>'
})
export class JoseComponent {
}
