<div class="container">
  <ds-my-dspace-new-submission *dsShowOnlyForRole="[roleTypeEnum.Submitter]"></ds-my-dspace-new-submission>
</div>

<div *ngIf="!subscribeStats">
<div id="aspect_submission_Submissions_div_subscribe-to-stats" class="container ng-tns-c141-24">
<p class="ds-paragraph">
<button (click)="subscribeToDepositStats()" style="background-color: #1d7491;" id="aspect_submission_Submissions_field_submit_stats_on" class="ds-button-field btn btn-default" name="submit_stats_on" type="submit">
<div style="color: white">Send Monthly Statistics On My Deposits</div>
</button>
</p>
</div>
</div>

<div *ngIf="subscribeStats">
<div id="aspect_submission_Submissions_div_subscribe-to-stats" class="container ng-tns-c141-24">
<p class="ds-paragraph">
<button (click)="unsubscribeToDepositStats()" style="background-color: #1d7491;" id="aspect_submission_Submissions_field_submit_stats_on" class="ds-button-field btn btn-default" name="submit_stats_on" type="submit">
<div style="color: white">Don't Send Monthly Statistics On My Deposits</div>
</button>
</p>
</div>
</div>

<ds-themed-search *ngIf="configuration && context"
           [configuration]="configuration"
           [configurationList]="(configurationList$ | async)"
           [context]="context"
           [viewModeList]="viewModeList"
></ds-themed-search>
